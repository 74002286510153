import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Container, Box, Typography, TextField, Button, styled, CircularProgress } from '@mui/material';
import axios from 'axios';

const LoginContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #00A4CCFF, #00D4AA);
`;

const LoginBox = styled(Box)`
  background-color: #212121;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.6);
  max-width: 400px;
  width: 100%;
`;

const LoginTitle = styled(Typography)`
  text-align: center;
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(90deg, #00A4CCFF, #00D4AA);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StyledTextField = styled(TextField)`
  & label.Mui-focused {
    color: #fff;
  }
  & label {
    color: #fff;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #00A4CCFF;
    }
    &:hover fieldset {
      border-color: #00D4AA;
    }
    &.Mui-focused fieldset {
      border-color: #00c684;
    }
    & input {
      color: #fff;
    }
  }
`;

const StyledButton = styled(Button)`
  margin-top: 30px;
  padding: 10px 0;
  background: linear-gradient(90deg, #00A4CCFF, #00D4AA, #00c684);
  color: #212121;
  font-weight: bold;
  &:hover {
    background: linear-gradient(90deg, #00A4CCFF, #00D4AA, #00c684);
  }
`;

const LinkText = styled(Typography)`
  text-align: center;
  margin-top: 20px;
  color: #fff;
  a {
    color: #00D4AA;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ErrorMessage = styled(Typography)`
  color: #ff1744;
  margin-bottom: 15px;
`;

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const trimmedUsername = username.trim();
    const trimmedPassword = password.trim();

    if (!trimmedUsername || !trimmedPassword) {
        setError('Username and password are required.');
        setLoading(false);
        return;
    }

    const data = {
        username: trimmedUsername,
        password: trimmedPassword,
    };

    try {
        const csrfMeta = document.querySelector('meta[name="csrf-token"]');
        const csrfToken = csrfMeta ? csrfMeta.getAttribute('content') : '';

        const response = await axios.post('https://client.ecosystemdev.com/login-post', data, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken, // Include CSRF token
            },
        });

        console.log('Login Successful:', response.data);
        localStorage.setItem('token', response.data.token);
        navigate('/dashboard');
    } catch (err: any) {
        if (err.response) {
            setError(err.response.data?.message || 'An error occurred during login.');
        } else {
            setError('An unexpected error occurred. Please try again.');
        }
        console.error('Error during login:', err);
    } finally {
        setLoading(false);
    }
};

  return (
    <LoginContainer>
      <LoginBox>
        <LoginTitle variant="h4">Sign In</LoginTitle>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <form onSubmit={handleSubmit}>
          <StyledTextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={handleInputChange}
            value={username}
          />
          <StyledTextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <StyledButton type="submit" fullWidth disabled={loading}>
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
          </StyledButton>
        </form>
        <LinkText>
          Don’t have an account? <RouterLink to="/signup">Sign Up</RouterLink>
        </LinkText>
      </LoginBox>
    </LoginContainer>
  );
};

export default Login;
