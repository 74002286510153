import React, { useState } from 'react';
import {
  Container,
  Typography,
  Button,
  TextField,
  styled,
  Box,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material';
import { AddHome, AccountBalanceWallet, Diversity3, AccountCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';

// Styled components
const AccountContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: #121212;
  padding-bottom: 56px;
`;

const Card = styled(Box)`
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  margin: 20px 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& label': {
      color: '#fff', // Default label color
    },
    '& input': {
      color: '#fff', // Input text color
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #fff', // Default underline color
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid #00D4AA', // Underline color on focus
    },
    '&:focus-within label': {
      color: '#00D4AA', // Change label color on focus
    },
    '&:focus-within input': {
      color: '#00D4AA', // Change input text color on focus
    },
    margin: '10px 0 15px', // Margin for spacing
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#00D4AA', // Change border color on focus
      },
      },
   
    
  }));
  
  const StyledButton = styled(Button)`
  background-color: #00d4aa; // Your site's button color
  color: #fff; // Button text color
  margin-top: 20px;

  &:hover {
    background-color: #00a4cc; // Darker shade for hover
  }
`;


const StyledBottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1e1e1e;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.5);
  padding: 10px 0;
z-index:1000;
  .MuiBottomNavigationAction-root {
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px);
    }

    &.Mui-selected {
      color: #00D4AA;
      transform: translateY(-5px);
    }
  }

  .MuiSvgIcon-root {
    font-size: 2rem;
  }

  .MuiBottomNavigationAction-label {
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

const AccountPage: React.FC = () => {
  const [name, setName] = useState('John Doe');
  const [email, setEmail] = useState('johndoe@example.com');
  const [phone, setPhone] = useState('+1234567890');
  const [balance, setBalance] = useState('$1000');
  const [address, setAddress] = useState('123 Main St, City, Country');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [activeTab, setActiveTab] = useState(3); // State for bottom navigation

  const handleUpdateInfo = () => {
    alert('Information updated successfully!');
  };

  const handleChangePassword = () => {
    if (newPassword === confirmPassword) {
      alert('Password changed successfully!');
    } else {
      alert('Passwords do not match!');
    }
  };

  return (
    <AccountContainer>
      <Typography variant="h4" color="#fff" sx={{ margin: '20px 0' }}>
        Account Information
      </Typography>

      <Card>
        <Typography variant="h6" color="#00D4AA">User Info</Typography>
        <Typography variant="body1" color="#fff">Name: {name}</Typography>
        <Typography variant="body1" color="#fff">Email: {email}</Typography>
        <Typography variant="body1" color="#fff">Phone: {phone}</Typography>
        <Typography variant="body1" color="#fff">Balance: {balance}</Typography>
        <Typography variant="body1" color="#fff">Address: {address}</Typography>
      </Card>

      <Card>
        <Typography variant="h6" color="#00D4AA">Update Information</Typography>
        <StyledTextField
          label="Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <StyledTextField
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <StyledTextField
          label="Phone"
          variant="outlined"
          fullWidth
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <StyledButton variant="contained" onClick={handleUpdateInfo}>
          Update Info
        </StyledButton>
      </Card>

      <Card>
        <Typography variant="h6" color="#00D4AA">Change Password</Typography>
        <StyledTextField
          label="New Password"
          type="password"
          variant="outlined"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <StyledTextField
          label="Confirm Password"
          type="password"
          variant="outlined"
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <StyledButton variant="contained" onClick={handleChangePassword}>
          Change Password
        </StyledButton>
      </Card>

      <StyledBottomNavigation
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
      >
        <BottomNavigationAction label="Home" icon={<AddHome />} component={Link} to="/dashboard" />
        <BottomNavigationAction label="Wallet" icon={<AccountBalanceWallet />} component={Link} to="/wallet" />
        <BottomNavigationAction label="Referral" icon={<Diversity3 />} component={Link} to="/refer" />
        <BottomNavigationAction label="Account" icon={<AccountCircle />} />
      </StyledBottomNavigation>
    </AccountContainer>
  );
};

export default AccountPage;
